$(document).on('change', '.select_project_primary_color', function () {
  let project_id = $(this).attr('data-project-id')
  let favourite_filter_val = $('.all_or_favourite_filter.dropdown-item-checked').attr('data-filter-value')
  let search_val = $('.search-project-input').val()

  $.ajax({
    url: '/projects/' + project_id,
    method: 'PATCH',
    data: {
      project: {
        primary_color: $(this).val()
      },
      favourite_filter: favourite_filter_val,
      search: search_val,
      no_flash: true
    },
    dataType : 'script'
  })
})

$(document).on('change, click', '.favourite_project_star', function () {
  let project_id = $(this).attr('data-project-id')
  let user_id = $(this).attr('data-current-user-id')
  let checked_favourite = $(this).attr('data-checked') == 'false'
  let user_preference_id = $(this).attr('data-user-preference-id')
  if(user_preference_id == 'undefined'){
    user_preference_id = null
  }
  $.ajax({
    url: '/projects/' + project_id,
    method: 'PATCH',
    data: {
      project: {
        user_project_preferences_attributes: [{
          id: user_preference_id,
          user_id: user_id,
          project_id: project_id,
          favourite: checked_favourite,
        }]
      },
      no_flash: true
    }
  })
})

$(document).on('click', '.all_or_favourite_filter', function(){
  let favourite_filter_val = $(this).attr('data-filter-value')
  let search_val = $('.search-project-input').val()
  let project_account_filter_val = $('.project_account_filter').val()
  $.ajax({
    url: '/projects',
    type: 'get',
    data: {
      favourite_filter: favourite_filter_val,
      search: search_val,
      account_filter: project_account_filter_val
    },
    dataType : 'script'
  })
})

$(document).on('keyup', '.search-project-input', function(){
  let favourite_filter_val = $('.all_or_favourite_filter.dropdown-item-checked').attr('data-filter-value')
  let search_val = $(this).val()
  let project_account_filter_val = $('.project_account_filter').val()
  $.ajax({
    url: '/projects',
    type: 'get',
    data: {
      favourite_filter: favourite_filter_val,
      search: search_val,
      account_filter: project_account_filter_val
    },
    dataType : 'script'
  })
})

$(document).on('change', '.project_account_filter', function(){
  let favourite_filter_val = $('.all_or_favourite_filter.dropdown-item-checked').attr('data-filter-value')
  let search_val = $('.search-project-input').val()
  let project_account_filter_val = $(this).val()
  $.ajax({
    url: '/projects',
    type: 'get',
    data: {
      favourite_filter: favourite_filter_val,
      search: search_val,
      account_filter: project_account_filter_val
    },
    dataType : 'script'
  })
})

$(document).on('change', '.project_task_attributes', function(){
  let project_id = $(this).attr('data-project-id')
  let project_section_id = $(this).attr('data-project-section-id')
  let id = $(this).attr('data-project-task-id')
  let project_name = $(this).val()
  $.ajax({
    url: '/projects/' + project_id + '/project_sections/' + project_section_id + '/project_tasks/' + id,
    type: 'patch',
    data: {
      name: project_name,
      no_flash: true
    }
  })
})

$(document).on('dp.change', '.project_task_due_date', function(){
  let project_id = $(this).attr('data-project-id')
  let project_section_id = $(this).attr('data-project-section-id')
  let project_task_id = $(this).attr('data-project-task-id')
  let due_date = $(this).val()
  $.ajax({
    url: '/projects/' + project_id + '/project_sections/' + project_section_id + '/project_tasks/' + project_task_id,
    method: 'patch',
    data: {
      id: project_task_id,
      due_date: due_date,
      no_flash: true
    },
    dataType: 'script'
  })
})

$(document).on('dp.change', '.project_task_due_date2', function(){
  let project_id = $(this).attr('data-project-id')
  let project_section_id = $(this).attr('data-project-section-id')
  let project_task_id = $(this).attr('data-project-task-id')
  let due_date = $(this).val()
  $.ajax({
    url: '/project_tasks/' + project_task_id + '/update_details',
    method: 'patch',
    data: {
      id: project_task_id,
      due_date: due_date,
      no_flash: true
    },
    dataType: 'script'
  })
})

$(document).on('click', '.create_project_tag', function(){
  console.log('Clicked Project Tag')
  let url = $(this).attr('data-url')
  let tag_name = $(this).closest('.dropdown-task-sts').find('.project_task_tag').val()
  $.ajax({
    url: url,
    method: 'post',
    data: {
      name: tag_name,
      no_flash: true
    }
  })
})

$(document).on('click', '.project_section_editable_title', function(e){
  $(this).focus()
  e.preventDefault()
})

$(document).on('blur keyup paste input', '.project_section_editable_title', function() {
  let project_section_id = $(this).attr('data-section-id')
  let title = $(this).text()
  $.ajax({
    url: '/project_sections/' + project_section_id,
    method: 'PATCH',
    data: {
      project_section: {
        title: title
      },
      no_flash: true
    }
  })
});

$(document).on('click', '.rename_project_section', function(){
  $(this).closest('.tab-label').find('.project_section_editable_title').focus();
})

$(document).on('click', '.project_task_details_link', function(){
  $('.project_section_task').removeClass('disabled_div')
  $(this).closest('.project_section_task').addClass('disabled_div')
})

$(document).on('change', '.project_task_details_name', function(){
  let project_task_id = $(this).attr('data-project-task-id')
  let task_name = $(this).val()
  $.ajax({
    url: '/project_tasks/' + project_task_id + '/update_details',
    method: 'patch',
    data: {
      id: project_task_id,
      name: task_name,
      no_flash: true
    },
    dataType: 'script'
  })
})

$(document).on('click', '.project_task_field_type_option', function(){
  let column_type = $(this).attr('data-column-type')
  $('.project_task_field_column_type').val(column_type)
  $('.projec_text_field_properties, .projec_text_field_options').addClass('d-none')
  if(column_type == 'Single Select') {
    $('.projec_text_field_options').removeClass('d-none')
  } else if(column_type == 'Number') {
    $('.projec_text_field_properties').removeClass('d-none')
  }
})

$(document).on('change', '.customize_project_task_field', function(){
  let active = $(this).prop('checked')
  let project_id = $(this).attr('data-project-id')
  let project_task_field_id = $(this).attr('data-project-task-field-id')
  $.ajax({
    url: '/projects/' + project_id + '/project_task_fields/' + project_task_field_id,
    method: 'patch',
    data: {
      project_task_field: {
        id: project_task_field_id,
        is_active: active
      }
    },
    dataType: 'script'
  })
})

$(document).on('click', '.custom_project_task_field_type_dropdown', function(){
  let primary_field_external = $(this).attr('data-option-value')
  $(this).closest('.customize-modal-dropdown').find('.customize-modal-text-box').val(primary_field_external)
})

$(document).on('click', '.custom_project_task_field_format_dropdown', function(){
  $('.custom_project_task_field_custom_label').addClass('d-none')
  let data_option_value = $(this).attr('data-option-value')
  $(this).closest('.customize-modal-dropdown').find('.customize-modal-text-box').val(data_option_value)
  $('.project_task_field_custom_format').val(data_option_value)
  if(data_option_value == 'Custom label'){
    $('.custom_project_task_field_custom_label').removeClass('d-none')
  }
  let format_obj = {'# Number': '', '% Percent': '%', '$ USD': 'US$', '€ EUR': '€', '¥ JPY': 'JP¥', '£ GBP': '£', '$ CAD': 'CA$', '$ AUD': 'A$', '$ MXN': 'MX$', 'R$ BRL': 'R$', 'Custom label': '', 'Unformatted': '' }
  $('.project_task_field_custom_label_field').val(format_obj[data_option_value])
})

$(document).on('click', '.custom_project_task_field_position_dropdown', function(){
  let data_option_value = $(this).attr('data-option-value')
  $(this).closest('.customize-modal-dropdown').find('.customize-modal-text-box').val(data_option_value)
  $('.project_task_field_custom_position').val(data_option_value)
})

$(document).on('click', '.custom_project_task_field_decimal_dropdown', function(){
  let data_option_value = $(this).attr('data-option-value')
  $(this).closest('.customize-modal-dropdown').find('.customize-modal-text-box').val(data_option_value)
  $('.project_task_field_custom_decimal').val(data_option_value)
})

$(document).on('focus', '.dynamic_project_task_number_field', function(){
  let custom_label = $(this).attr('data-custom-label')
  let current_value = $(this).val()
  var new_value = current_value.replace(custom_label,'');
  $(this).val(new_value)
});

$(document).on('blur', '.dynamic_project_task_number_field', function(){
  let custom_label = $(this).attr('data-custom-label')
  let custom_decimal = $(this).attr('data-custom-decimal')
  let current_value = $(this).val()
  let project_task_id = $(this).attr('data-project-task-id')
  let project_task_field_id = $(this).attr('data-task-field-id')
  let project_task_field_value_id = $(this).attr('data-project-task-field-value-id')
  var new_value = custom_label + parseFloat(current_value).toFixed(custom_decimal)
  $(this).val(new_value)
  $.ajax({
    url: '/project_tasks/' + project_task_id + '/update_details',
    method: 'patch',
    data: {
      project_task_field_values_attributes: [{
        id: project_task_field_value_id,
        project_task_id: project_task_id,
        project_task_field_id: project_task_field_id,
        value: current_value
      }],
      no_flash: true
    },
    dataType: 'script'
  })

});

$(document).on('blur', '.dynamic_project_task_text_field', function(){
  let current_value = $(this).val()
  let project_task_id = $(this).attr('data-project-task-id')
  let project_task_field_id = $(this).attr('data-task-field-id')
  let project_task_field_value_id = $(this).attr('data-project-task-field-value-id')
  $.ajax({
    url: '/project_tasks/' + project_task_id + '/update_details',
    method: 'patch',
    data: {
      project_task_field_values_attributes: [{
        id: project_task_field_value_id,
        project_task_id: project_task_id,
        project_task_field_id: project_task_field_id,
        value: current_value
      }],
      no_flash: true
    },
    dataType: 'script'
  })
});

$(document).on('change', '.dynamic_project_task_select_field', function(){
  let current_value = $(this).val()
  let project_task_id = $(this).attr('data-project-task-id')
  let project_task_field_id = $(this).attr('data-task-field-id')
  let project_task_field_value_id = $(this).attr('data-project-task-field-value-id')
  $.ajax({
    url: '/project_tasks/' + project_task_id + '/update_details',
    method: 'patch',
    data: {
      project_task_field_values_attributes: [{
        id: project_task_field_value_id,
        project_task_id: project_task_id,
        project_task_field_id: project_task_field_id,
        value: current_value
      }],
      no_flash: true
    },
    dataType: 'script'
  })
});

jQuery.fn.swapWith = function(to) {
  return this.each(function() {
      var copy_to = $(to).clone(true);
      var copy_from = $(this).clone(true);
      $(to).replaceWith(copy_from);
      $(this).replaceWith(copy_to);
  });
};

$(document).on('click', '.move_project_task_field', function(){
  let swap_side = $(this).attr('data-swap-side')
  let parent_element = $(this).closest('.project_task_table_header')
  if(swap_side == 'left'){
    parent_element.swapWith(parent_element.prev());
  } else {
    parent_element.swapWith(parent_element.next());
  }
  let update_sort_url = $('.project_task_table_header').attr('data-update-sort-url');
  let sorting_ids = $('.project_task_table_header').sortable('toArray', { attribute: "data-field-id" });
  $.ajax({
    url: update_sort_url,
    type: 'PUT',
    data: {
      project: { sorting_ids: sorting_ids }
    },
    dataType: 'script'
  });
})

$(document).on('click', '.remove_project_task_assignee', function(){
  let task_field_id = $(this).attr('data-task-id')
  let current_element = $(this)
  $.ajax({
    url: '/project_tasks/' + task_field_id + '/update_details',
    type: 'PATCH',
    data: {
      user_id: null,
      no_flash: true
    },
    dataType: 'script',
    success: function(data){
      let parent_div = current_element.closest('.assign-task-user')
      parent_div.find('.project_drp_assignee_not_added').removeClass('d-none')
      parent_div.find('.project_drp_assignee_added').addClass('d-none')
    }
  });
})

window.blockUI = function(){
  $.blockUI({
    message: "<h3> Please wait... </h3>",
    css: {
      border: 'none',
      padding: '15px',
      backgroundColor: '#000',
      '-webkit-border-radius': '10px',
      '-moz-border-radius': '10px',
      opacity: .5,
      color: '#fff'
    }
  });
}

$(document).on('click', '.project_invite_user_email_btn', function(){
  let element_to_be_validated = $('.project_invite_user_email').parsley()
  $(this).closest('.project_invitation_div').find('.email_error').addClass('d-none')
  if(element_to_be_validated.isValid()){
    let email = $('.project_invite_user_email').val()
    let url = $(this).attr('data-url')
    $.ajax({
      url: url,
      type: 'POST',
      data: {
        invitee_mail: email
      }
    })
  } else {
    let email_error_element = $(this).closest('.project_invitation_div').find('.email_error')
    let error_text = element_to_be_validated.getErrorsMessages().join(', ')
    email_error_element.text(error_text)
    email_error_element.removeClass('d-none')
  }
})

$(document).on('click', '.change_project_member_privilege', function(){
  let privilege = $(this).attr('data-privilege')
  let user_project_preference_id = $(this).closest('ul').attr('data-user-project-preference-id')
  let user_project_preference_path = $(this).closest('ul').attr('data-update-url')
  $.ajax({
    url: user_project_preference_path,
    type: 'PATCH',
    data: {
      user_project_preference: {
        id: user_project_preference_id,
        privilege: privilege
      },
      active_tab: 'members'
    }
  })
})

$(document).on('click', '.remove_member_from_project', function(){
  let privilege = $(this).attr('data-privilege')
  let user_project_preference_id = $(this).closest('ul').attr('data-user-project-preference-id')
  let user_project_preference_path = $(this).closest('ul').attr('data-update-url')
  $.ajax({
    url: user_project_preference_path,
    type: 'DELETE',
    data: {
      user_project_preference: {
        id: user_project_preference_id,
      },
      active_tab: 'members'
    }
  })
})

$(document).on("mousedown", ".ui-resizable-handle", function() {
  $('.ui-resizable-handle:active, .ui-resizable-handle:focus').css('height', $('.resp-table').parent().outerHeight(true) + 130)
});
$(document).on("mouseup", ".ui-resizable-handle", function() {
  $('.ui-resizable-handle:hover, .ui-resizable-handle:active, .ui-resizable-handle:focus').css('height', '100%')
});