$(document).on('click', '.plus-qty', function () {
  let product_id = fetch_product_id($(this))
  let current_path = this.dataset.currentPath
  increase_qty(product_id, current_path)
})

$(document).on('click', '.minus-qty', function () {
  let product_id = fetch_product_id($(this))
  let current_path = this.dataset.currentPath
  decrease_qty(product_id, current_path)
})

$(document).on('click', '.add_into_cart', function () {
  let product_id = this.dataset.id;
  let quantity = parseInt($(this).closest('.item_parent').find('.current-qty').val())
  let path = this.dataset.currentPath
  add_into_cart(product_id, quantity, path)
})


$(document).on('click', '.minus-main-qty', function(){
  let qt = $(this).closest('.item_parent').find('.main-qty').val()
  let price =  $(this).closest('.item_parent').find('.main-price')[0].dataset.price
  updated_qt = parseInt(qt) - 1
  total_price = parseFloat(price) * updated_qt
  if (updated_qt > 0) {
    $(this).closest('.item_parent').find('.main-qty').val(updated_qt)
    $(this).closest('.item_parent').find('.product_live_price').text(total_price.toFixed(2))
  }
})

$(document).on('click', '.plus-main-qty', function(){
  let qt = $(this).closest('.item_parent').find('.main-qty').val()
  let price =  $(this).closest('.item_parent').find('.main-price')[0].dataset.price
  updated_qt = parseInt(qt) + 1
  total_price = parseFloat(price) * updated_qt
  $(this).closest('.item_parent').find('.main-qty').val(updated_qt)
  $(this).closest('.item_parent').find('.product_live_price').text(total_price.toFixed(2))
})

$(document).on('change keyup focusout', '.main-qty', function(){
  let qt = $(this).val()
  let price =  $(this).closest('.item_parent').find('.main-price')[0].dataset.price
  total_price = parseFloat(price) * parseInt(qt)
  if (qt == "" || 0) {
    total_price = '0.00'
  } else {
    total_price = total_price.toFixed(2)
  }
  $(this).closest('.item_parent').find('.product_live_price').text(total_price)
})

$(document).on('focusout', '.cart-current-qty', function(){
  let product_id = fetch_product_id($(this))
  let current_path = this.dataset.currentPath
  let current_qty = this.value
  update_qty(product_id, current_qty, current_path)
})

function update_qty(product_id, qty, path) {
  add_into_cart(product_id, qty, path);
}

function increase_qty(product_id, current_path) {
  let prev_qty = fetch_live_qty(product_id)
  let latest_qty = parseInt(prev_qty) + 1
  add_into_cart(product_id, latest_qty, current_path);
  $('.qty-box-' + product_id).val(latest_qty);
}

function decrease_qty(product_id, current_path) {
  let prev_qty = fetch_live_qty(product_id)
  let latest_qty = parseInt(prev_qty) - 1
  if (parseInt(latest_qty) > 0) {
    add_into_cart(product_id, latest_qty, current_path);
    $('.qty-box-' + product_id).val(latest_qty);
  }
}

function fetch_product_id(e) {
  let product_id = $(e).data('product-id')
  return product_id;
}

function fetch_live_qty(product_id) {
  let live_qty = parseInt($('.qty-box-' + product_id).val());
  return live_qty
}

function add_into_cart(product_id, qty, path) {
  $.ajax({
    url: '/add_into_cart',
    type: 'get',
    data: {
      product_id: product_id,
      "product[product_id]": product_id,
      "product[quantity]": qty,
      current_path: path
    },
    dataType: "script"
  });
}

$(document).on('click', '.remove-product-from-cart', function () {
  $.ajax({
    url: '/remove_product_from_cart',
    type: "put",
    data: {
      product_id: this.dataset.id,
      current_path: this.dataset.currentPath
    },
    dataType: "script"
  });
})

$(document).on('click', '.change-product-qty-cart', function () {
  let quantity = $(this).val();
  $.ajax({
    url: '/update_product_qty_from_cart',
    type: "put",
    data: {
      product_id: this.dataset.id,
      quantity: quantity
    },
    dataType: "script"
  });
})

// $(document).on('click', '.add-customer-detail', function(){
//   let user_id = this.dataset.userId;
//   $.ajax({
//     url: '/update_customer_into_cart',
//     type: "put",
//     data: {
//       user_id: user_id,
//     },
//     dataType: "script"
//   })
// })