window.mobile_number_with_country_code = function (form_element) {
  $('.phone-number').each(function () {
    let input = $(this)[0];
    window.intlTelInput(input, {
      preferredCountries: ['US'],
      formatOnDisplay: false,
      separateDialCode: true,
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@16.0.3/build/js/utils.js"
    });
    update_format($(this))
  })
  $(form_element).submit(function () {
    if ($(this).parsley().validate() == true) {
      $('.phone-number').each(function () {
        country_code = $(this).parent().find('.iti__selected-dial-code').text()
        only_number = $(this).val().replace(/[- )(]/g, '');
        country_code = (only_number >= 9) ? country_code : ''
        phone_number = country_code + only_number
        $(this).val(phone_number);
      })
    }
  })

  function update_format(e) {
    phone_number = /^(1\s?)?((\([0-9]{3}\)-)|[0-9]{3})[\s\-]?[\0-9]{3}-[\s\-]?[0-9]{4}$/
    space_regex = /\(?\d{3}\)?[ ]\d{3}?[ ]\d{4}/
    dash_regex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
    var curchr = e.val().length;
    var curval = e.val();
    if (curchr == 3 && curval.indexOf("(") <= -1) {
      if (e.which != 8) {
        e.val("(" + curval + ")" + " ");
      }
    } else if (curchr == 4 && curval.indexOf("(") > -1) {
      if (e.which != 8) {
        e.val(curval + ")-");
      }
    } else if (curchr == 5 && curval.indexOf(")") > -1) {
      if (e.which != 8) {
        e.val(curval + "-");
      }
    } else if (curchr == 9) {
      if (e.which != 8) {
        e.val(curval + "-");
      }
      e.attr('maxlength', '14');
    } else if (curchr == 10 && curval.indexOf("(") != 0) {
      if (e.which != 8) {
        e.val("(" + curval.slice(0, 3) + ")" + " " + curval.slice(3, 6) + "-" + curval.slice(6, 10))
      }
      e.attr('maxlength', '14');
      e.parsley().validate();
    }
  }

  $(document).on('keyup keypress change', '.phone-number', function (e) {
    phone_number = /^(1\s?)?((\([0-9]{3}\)-)|[0-9]{3})[\s\-]?[\0-9]{3}-[\s\-]?[0-9]{4}$/
    space_regex = /\(?\d{3}\)?[ ]\d{3}?[ ]\d{4}/
    dash_regex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
    var curchr = this.value.length;
    var curval = $(this).val();
    if (curchr == 3 && curval.indexOf("(") <= -1) {
      if (e.which != 8) {
        $(this).val("(" + curval + ")" + " ");
      }
    } else if (curchr == 4 && curval.indexOf("(") > -1) {
      if (e.which != 8) {
        $(this).val(curval + ")-");
      }
    } else if (curchr == 5 && curval.indexOf(")") > -1) {
      if (e.which != 8) {
        $(this).val(curval + "-");
      }
    } else if (curchr == 9) {
      if (e.which != 8) {
        $(this).val(curval + "-");
      }
      $(this).attr('maxlength', '14');
    } else if (curchr == 10 && curval.indexOf("(") != 0) {
      if (e.which != 8) {
        $(this).val("(" + curval.slice(0, 3) + ")" + " " + curval.slice(3, 6) + "-" + curval.slice(6, 10))
      }
      $(this).attr('maxlength', '14');
      $(this).parsley().validate();
    }
  });
}
