import consumer from "./consumer"

consumer.subscriptions.create("MessageChannel", {
  connected() {
    console.log("------------connected to the channel--------------")
  },

  disconnected() {
    console.log("------------disconnected--------------")
  },

  received(data) {
    var id = data.id
    $('#messageCampaign-' + id).append('<div class="received-message"><p>' + data['conversation'].message_text +'</p></div>')
    $('.conversation_notification').html(data['un_read_count'])
    $('.sms_conversation')[0].innerHTML = data['partial_html']
    $(document).ready(function() {
      const filterTabs = document.querySelectorAll('.filter-tab');
      const messageCards = document.querySelectorAll('.message-inbox');
      const preFilter = localStorage.getItem('selectedTab') || 'all';
      filterTabs.forEach(t => t.classList.remove('active'));
      document.querySelector("[data-filter="+ preFilter+"]").classList.add('active');
      messageCards.forEach(card => {  
        if (preFilter === 'all') {
          card.style.display = 'flex';
        } else if (card.getAttribute('data-status') === preFilter) {
          card.style.display = 'flex';
        } else {
          card.style.display = 'none';
        }
      });
          
      filterTabs.forEach(tab => {
        tab.addEventListener('click', function () {
          // Remove active class from all tabs and set it to the clicked tab
          filterTabs.forEach(t => t.classList.remove('active'));
          this.classList.add('active');
  
          // Get the filter type
          const filter = this.getAttribute('data-filter');
          localStorage.setItem('selectedTab', filter)
          // Filter messages based on the clicked filter
          messageCards.forEach(card => {
            if (filter === 'all') {
              card.style.display = 'flex';
            } else if (card.getAttribute('data-status') === filter) {
              card.style.display = 'flex';
            } else {
              card.style.display = 'none';
            }
          });
        });
      });
    });
    $('.unread-count').html(data['un_read_count'])
  }
  
});

