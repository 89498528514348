$(document).on('keyup keypress', '.phone-number55', function(e){
  phone_number = /^(1\s?)?((\([0-9]{3}\)-)|[0-9]{3})[\s\-]?[\0-9]{3}-[\s\-]?[0-9]{4}$/
  space_regex = /\(?\d{3}\)?[ ]\d{3}?[ ]\d{4}/
  dash_regex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
  var curchr = this.value.length;
  var curval = $(this).val();
  if (curchr == 3 && curval.indexOf("(") <= -1) {
    if (e.which != 8){
      $(this).val("(" + curval + ")" + " ");
    }
  } else if (curchr == 4 && curval.indexOf("(") > -1) {
    if (e.which != 8){
      $(this).val(curval + ")-");
    }
  } else if (curchr == 5 && curval.indexOf(")") > -1) {
    if (e.which != 8){
      $(this).val(curval + "-");
    }
  } else if (curchr == 9) {
    if (e.which != 8){
      $(this).val(curval + "-");
    }
    $(this).attr('maxlength', '14');
  }
});

window.validate_file_size = (file_element, max_file_size_in_MB) => {
  for (let i = 0; i < file_element.files.length; i++) {
    let fileSize = file_element.files[i].size / 1024 / 1024
    if (fileSize > max_file_size_in_MB) {
      alert('File size exceeds ' + max_file_size_in_MB + ' MB')
      $(file_element).val('')
      return false
    } 
  }
  return true
}

window.Parsley.addValidator('filesize', (function (value, requirement) {
  var input = $('input[type="file"]');
  var maxsize = requirement;
  var max_bytes = maxsize * 1000, file = input[0].files[0];
  return file.size <= max_bytes;
  var allowedExtensions, fileExtension;
}), 32).addMessage('en', 'filesize', 'Please upload under 1MB file');

$(document).on('change', '.dynamic_checkbox', function(){
  let self_table = $(this).closest('table')
  let id = $(this).attr('data-id')
  let show_path = $(this).attr('data-show-path')
  let edit_path = $(this).attr('data-edit-path')
  let destroy_path = $(this).attr('data-destroy-path')
  let archived_path = $(this).attr('data-archived-path')
  let invitation_path = $(this).attr('data-invitation-path')
  let header_btns_path = $(this).attr('data-header-btns-url')
  if($('.dynamic_checkbox:checked').length == 1){
    self_table.find('.dynamic_table_header_tr').addClass('d-none')
    self_table.find('.table_action_header').removeClass('d-none')
    $.ajax({
      url: header_btns_path,
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      type: 'GET',
      data: {
        id: id,
        show_path: show_path,
        edit_path: edit_path,
        destroy_path: destroy_path,
        invitation_path: invitation_path,
        archived_path: archived_path
      },
    })
  } else if($('.dynamic_checkbox:checked').length > 1) {
    $('.dynamic_table_header_tr').addClass('d-none')
    $('.table_destroy_all_header').removeClass('d-none')
  } else {
    self_table.find('.dynamic_table_header_tr').addClass('d-none')
    self_table.find('.table_main_header').removeClass('d-none')
  }
})

$(document).on('click', '.table_delete_selected_records', function(){
  capitalize_text = this.closest('table').dataset.tableName
  // dynamic_record_name = this.dataset.destroyAllUrl.split('/')[1]
  // capitalize_text == 'Settings' ? 'Users' : capitalize_text
  let result = window.confirm(`Are you sure, you want to delete the selected ${capitalize_text}?`);
  let checked_checkbox_ids = []
  $('.dynamic_checkbox:checked').each(function(){
    checked_checkbox_ids.push(parseInt($(this).attr('data-id')))
  })

  if(result){
    $.ajax({
      url: $(this).attr('data-destroy-all-url'),
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      type: 'DELETE',
      data: {
        ids: checked_checkbox_ids,
      },
    })
  }
})

$(document).on('click', '.Rocket-btn', function(){
  $('.middle_part').toggleClass('RightTuchShow')
})

window.getFirstLetters = (str) => {
  const firstLetters = str
    .split(' ')
    .map(word => word[0])
    .join('');
  return firstLetters;
}

var autocomplete;
var address1Field;
var address2Field;
var postalField;

window.fillInAddress = () => {
  const place = autocomplete.getPlace();
  var address1 = "";
  var postcode = "";
  for (const component of place.address_components) {
    const componentType = component.types[0];

    switch (componentType) {
      case "street_number": {
        address1 = `${component.long_name} ${address1}`;
        break;
      }

      case "route": {
        address1 += component.short_name;
        break;
      }

      case "postal_code": {
        postcode = `${component.long_name}${postcode}`;
        break;
      }

      case "postal_code_suffix": {
        postcode = `${postcode}-${component.long_name}`;
        break;
      }
      case "locality":
        $(".city").val(component.long_name)
        break;

      case "administrative_area_level_1": {
        $(".state").val(component.long_name)
        break;
      }
      case "country":
        $(".country").val(component.long_name)
        break;
    }
  }
  address1Field.value = address1;
  postalField.value = postcode;
  // address2Field.focus();
}

window.initAutocomplete = () => {
  address1Field = $(".street_address")[0];
  // address2Field = document.querySelector("#address2");
  postalField = $(".zip_code")[0];
  autocomplete = new google.maps.places.Autocomplete($(".street_address")[0], {
    fields: ["address_components", "geometry"],
    types: ["address"],
  });
  // address1Field.focus();
  autocomplete.addListener("place_changed", fillInAddress);
}

$(document).on('keyup', '.form-control', function(){
  var field = $(this);
  var $field = $(field) || field;
  if ($field.siblings('.message').length > 0) {
    $field.parent().prev('.field_with_errors').find('label').unwrap();
    $field.unwrap();
    $field.next('.message').remove();
    return true;
  }
  return false;
})